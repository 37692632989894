import { buildSearchQuery } from "common/util/search";
import { fetchCurrentProgramYear } from "common/entities/ProgramYear/actions";
import { trimObject } from "common/util";

/**
 * Updates credentials.
 */
export function updateSiteStafferCredentials(
  siteStaffId,
  currentUserName,
  newUserName,
  currentPassword,
  newPassword
) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: "UPDATE_SITE_STAFFER_CREDENTIALS",
      payload: {
        request: {
          method: "put",
          url: `/siteStaff/${siteStaffId}/credentials`,
          data: {
            oldCredentials: {
              userName: currentUserName,
              password: currentPassword,
            },
            newCredentials: { userName: newUserName, password: newPassword },
          },
        },
      },
    });
  };
}

/**
 * Fetches a site staffer by ID.
 */
export function fetchSiteStafferById(siteStaffId) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: "FETCH_SITE_STAFFER",
      payload: {
        request: {
          method: "get",
          url: `/siteStaff/${siteStaffId}`,
        },
      },
    }).catch((e) => {
      // there is one error we handle
      if (e && e.code && e.code === 1700) {
        // no site staffer with the given ID
        return null;
      } else {
        throw e;
      }
    });
  };
}

/**
 * Adds a new site staffer.
 */
export function addSiteStaffer(siteStaffer) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // clean the data
    siteStaffer = trimObject(siteStaffer);

    // make the call
    return dispatch({
      type: "ADD_SITE_STAFFER",
      payload: {
        request: {
          method: "post",
          url: `/siteStaff`,
          data: siteStaffer,
        },
      },
    });
  };
}

/**
 * Updates an existing site staffer.
 */
export function updateSiteStaffer(siteStaffer) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // clean the data
    siteStaffer = trimObject(siteStaffer);

    // make the call
    return dispatch({
      type: "UPDATE_SITE_STAFFER",
      payload: {
        request: {
          method: "put",
          url: `/siteStaff/${siteStaffer.id}`,
          data: siteStaffer,
        },
      },
    });
  };
}

/**
 * Fetches all subordinate site staff to a given site staffer for
 * the current program year.
 */
export function fetchSubordinateSiteStaffBySiteYear(siteYearId) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: "FETCH_SITE_STAFF_BY_SITE_YEAR",
      payload: {
        request: {
          method: "get",
          url: `/programYears/current/siteStaff/${siteYearId}`,
        },
      },
    });
  };
}

/**
 * Fetches site staff attendance for a given day.
 */
export function fetchSiteStaffAttendance(
  programYearId,
  siteYearId,
  date,
  type = "school"
) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: "FETCH_SITE_STAFF_ATTENDANCE",
      payload: {
        request: {
          method: "get",
          url: `/siteStaff/attendance/${date}?${buildSearchQuery({
            programYearId: programYearId,
            siteYearId: siteYearId,
            type: type,
          })}`,
        },
      },
    });
  };
}

/**
 * Sets a set of site staffers' states for a given day.
 */
export function setSiteStaffState(siteStaff, date, state, type = "school") {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: "SET_SITE_STAFF_STATE",
      payload: {
        request: {
          method: "put",
          url: `/siteStaff/attendance`,
          data: siteStaff.map((siteStaff) => ({
            siteStaffId: siteStaff.id,
            attendanceDate: date,
            state: state,
            type: type,
          })),
        },
      },
    });
  };
}

/**
 * Fetches all site years from the current program year to which a
 * staffer has access.
 */
export function fetchCurrentSiteYearsBySiteStaff(siteStaffId) {
  // using thunk middleware, so we return a function
  return (dispatch) =>
    dispatch(fetchCurrentProgramYear()).then((currentProgramYear) =>
      dispatch(fetchSiteYearsBySiteStaff(currentProgramYear.id, siteStaffId))
    );
}

/**
 * Fetches all site years from a specific program year to which a
 * staffer has access.
 */
export function fetchSiteYearsBySiteStaff(programYearId, siteStaffId) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: "FETCH_CURRENT_SITE_YEARS_BY_SITE_STAFF",
      payload: {
        request: {
          method: "get",
          url: `/programYears/${programYearId}/siteStaff/${siteStaffId}/siteYears`,
        },
      },
    });
  };
}
