import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import { BasePureComponent } from "common/components/Base";
import { Breadcrumbs } from "common/components/Breadcrumbs";
import { getSessionId } from "common/util/session";
import { createLoadable } from "common/Loadable";

// load components dynamically
const Home = createLoadable(() => import("app/scenes/Home"));
const SetPassword = createLoadable(() => import("app/scenes/SetPassword"));
const SiteStaffApplication = createLoadable(() =>
  import("app/scenes/SiteStaffApplication")
);
const SiteStaffEmployeeRecord = createLoadable(() =>
  import("app/scenes/SiteStaffEmployeeRecord")
);
const SiteStaffScreening = createLoadable(() =>
  import("app/scenes/SiteStaffScreening")
);
const Feedback = createLoadable(() => import("app/scenes/Feedback"));
const Info = createLoadable(() => import("common/scenes/Info"));
const SiteStaff = createLoadable(() => import("app/scenes/SiteStaff"));
const LogHours = createLoadable(() => import("app/scenes/LogHours"));
const ChildProtectionPolicy = createLoadable(() =>
  import("app/scenes/ChildProtectionPolicy")
);

/*
 * The application's main content. This content changes as the user navigates.
 * Note that routes need to be ordered from most specific to least specific.
 */
class Main extends BasePureComponent {
  render() {
    // parent
    super.render();

    // for securing some routes behind login
    const loggedIn = getSessionId() && this.props.siteStaff ? true : false;
    const secureRoute = (component) => {
      // if logged in, we need the data guard
      return loggedIn ? (
        component
      ) : (
        <Redirect
          to={{
            pathname:
              "/" +
              (this.props.location &&
              this.props.location.pathname &&
              this.props.location.pathname.length > 0
                ? "~" + encodeURIComponent(this.props.location.pathname)
                : ""),
          }}
        />
      );
    };

    return (
      <div className="fsp-main">
        <div className="fsp-page">
          {loggedIn && <Breadcrumbs current={this.props.location.pathname} />}
          <Switch>
            {/* set password */}
            <Route exact path="/password/:token" component={SetPassword} />

            {/* application info */}
            <Route
              exact
              path="/info"
              render={(props) =>
                secureRoute(<Info {...this.props} {...props} />)
              }
            />

            {/* profile management */}
            <Route
              exact
              path="/account"
              render={(props) => (
                // note that this route is not secure on purpose: new account creation
                <SiteStaff {...this.props} {...props} />
              )}
            />

            {/* application */}
            <Route
              exact
              path="/applications/application"
              render={(props) =>
                secureRoute(<SiteStaffApplication {...this.props} {...props} />)
              }
            />

            {/* employee record */}
            <Route
              exact
              path="/employeeRecords/employeeRecord"
              render={(props) =>
                secureRoute(
                  <SiteStaffEmployeeRecord {...this.props} {...props} />
                )
              }
            />

            {/* screening */}
            <Route
              exact
              path="/introduction"
              render={(props) =>
                secureRoute(<SiteStaffScreening {...this.props} {...props} />)
              }
            />

            {/* log hours */}
            <Route
              exact
              path="/logHours"
              render={(props) =>
                secureRoute(<LogHours {...this.props} {...props} />)
              }
            />

            {/* child protection policy */}
            <Route
              exact
              path="/cpp"
              render={(props) =>
                secureRoute(
                  <ChildProtectionPolicy {...this.props} {...props} />
                )
              }
            />

            {/* feedback */}
            <Route
              exact
              path="/feedback"
              render={(props) =>
                secureRoute(<Feedback {...this.props} {...props} />)
              }
            />

            {/* redirect */}
            <Route
              exact
              path="/~:redirect"
              render={(props) => <Home {...this.props} {...props} />}
            />

            {/* default */}
            <Route render={(props) => <Home {...this.props} {...props} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

// make router props accessible; this is necessary to
// drive re-renders based on path changes
Main = withRouter(connect()(Main));

// set prop types and required-ness
Main.propTypes = { siteStaff: PropTypes.object };

export default Main;
